'use strict';

// stylesheet import
import '../sass/main.sass';

import { createApp } from 'vue';
import App from './vue/app.vue';
import { IntersectionObserverToggleClass } from './modules/class-intersection-observer-toggleclass.js';
import { IsLegacyIE } from './modules/checking-legacy-ie.js';
import { FindOS } from './modules/checking-os.js';
import { FindBrowser } from './modules/checking-browser.js';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
// const $ = require('jQuery');

// Vue.js
const app = createApp(App);
app.mount('#app');

// jQuery
// $(function() {
  // console.log('jQuery is ready.');
// });

// GSAP
gsap.registerPlugin(ScrollTrigger);
gsap.fromTo(
  '.js-mask-animation-firstview--element',
  {
    'clip-path': 'inset(0 0 0 100%)'
  },
  {
    'clip-path': 'inset(0 0 0 -100%)',
    delay: 0.1,
    duration: 2.4,
    ease: 'power1.out'
  }
);
gsap.fromTo(
  '.js-fadein-animation-firstview--element',
  {
    y: 32,
    autoAlpha: 0
  },
  {
    y: 0,
    autoAlpha: 1,
    delay: 1.0,
    duration: 0.8,
    ease: 'power1.out'
  }
);
gsap.fromTo(
  '.js-mask-animation-scroll-1st--element',
  {
    'clip-path': 'inset(0 100% 0 0)'
  },
  {
    'clip-path': 'inset(0 -100% 0 0)',
    delay: 0.1,
    duration: 2.4,
    ease: 'power1.out',
    scrollTrigger: {
      trigger: '.js-mask-animation-scroll-1st--trigger',
      start: 'top 90%',
      end: 'bottom 30%',
      scrub: 2.4
    }
  }
);
gsap.fromTo(
  '.js-mask-animation-scroll-2nd--element',
  {
    'clip-path': 'inset(0 0 0 100%)'
  },
  {
    'clip-path': 'inset(0 0 0 -100%)',
    delay: 0.1,
    duration: 2.4,
    ease: 'power1.out',
    scrollTrigger: {
      trigger: '.js-mask-animation-scroll-2nd--trigger',
      start: 'top 90%',
      end: 'bottom 30%',
      scrub: 2.4
    }
  }
);
gsap.fromTo(
  '.js-mask-animation-scroll-3rd--element',
  {
    'clip-path': 'inset(0 100% 0 0)'
  },
  {
    'clip-path': 'inset(0 -100% 0 0)',
    delay: 0.1,
    duration: 2.4,
    ease: 'power1.out',
    scrollTrigger: {
      trigger: '.js-mask-animation-scroll-3rd--trigger',
      start: 'top 90%',
      end: 'bottom 30%',
      scrub: 2.4
    }
  }
);
gsap.fromTo(
  '.js-mask-animation-scroll-4th--element',
  {
    'clip-path': 'inset(0 0 0 100%)'
  },
  {
    'clip-path': 'inset(0 0 0 -100%)',
    delay: 0.1,
    duration: 2.4,
    ease: 'power1.out',
    scrollTrigger: {
      trigger: '.js-mask-animation-scroll-4th--trigger',
      start: 'top 90%',
      end: 'bottom 30%',
      scrub: 2.4
    }
  }
);

// Intersection Observer
new IntersectionObserverToggleClass({
  selector: '.observe_target', 
  className: 'observed', 
  toggle: true
});

// checking legacy IE
IsLegacyIE();

// checking OS
FindOS();

// checking browser
FindBrowser();