<script>
  export default {
    data() {
      return {
        startScrollPosition: 0,
        isHeaderShown: false
      }
    },
    mounted() {
      window.addEventListener('scroll', () => {
        this.checkScrollDirection();
      });
    },
    methods: {
      checkScrollDirection() {
        this.isHeaderShown = window.scrollY <= this.startScrollPosition && 300 <= window.scrollY;
        this.startScrollPosition = window.scrollY;
      },
      scrollTop() {
        window.scrollTo(0, 0);
      },
      phoneCall(tel) {
        if(window.confirm('行政書士 Defying Gravity 法務事務所に発信しますか？')) {
          location.href = `tel:${tel}`;
        } else {
          window.alert('発信がキャンセルされました');
        }
      }
    }
  }
</script>

